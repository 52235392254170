@import ../../styles/helpers

.shop
    // width: 70vw
    +d 
        width: 85vw
    +t
        width: 80vw
    +m
        width: 95vw

.backgroundImage
    transition: filter 0.3s ease

.backgroundImage:hover
    filter: brightness(30%)
    .edit
        visibility: visible

.backgroundImage:hover + .edit,
.edit:hover
    visibility: visible/* Show the edit icon when hovering over the background */

.outer
    max-width: 450px !important
    width: 100% !important

.Requst_btn_container
    +m
        width: 100%

.Request_btn
    width: max-content
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    margin-left: 8px
    margin-right: 15px
    box-shadow: 0 0 0 2px #EFEFEF inset
    +dark
        box-shadow: inset 0 0 0 2px #272B30

    +t
        margin-right: 5px
    +m
        flex-grow: 1
        width: 100%
        margin-right: 15px
        margin-left: 0px
    &:hover,
    &.active
        box-shadow: 0 0 0 2px #1A1D1F inset
        color: #1A1D1F
        +dark
            box-shadow: inset 0 0 0 2px $n5
            color: white

.background

.background
    width: 100%
    margin: 0 auto
    margin-bottom: 12px
    height:24vw
    max-height:450px
    +d
       height: 28vw
    +t
        height: 27vw 
    +m
        height: 32vw
    img
        width: 100%
        height: 100%
        object-fit: contain
        border-radius: 8px

.card
    max-width: 1200px
    margin: 0 auto

.control
    display: flex
    align-items: center
    justify-content: space-evenly
    margin-bottom: 32px
    +m
        flex-wrap: wrap

.nav
    display: flex
    margin-right: auto
    +m
        width: 100%
        margin: 0 0 16px

.link
    width: max-content
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex-grow: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.dropdownBox
    +m
        flex-grow: 1

.filters
    margin-left: 12px
    +m
        margin-left: 12px

.products
    display: flex
    flex-wrap: wrap
    margin: -32px -12px 0
    +a
        display: block
        margin: 0

.product
    flex: 0 0 calc(33.333% - 24px)
    width: calc(33.333% - 24px)
    margin: 32px 12px 0
    +t
        flex: 0 0 calc(50% - 24px)
        width: calc(50% - 24px)
    +a
        width: 100%
        margin: 24px 0 0

.foot
    margin-top: 40px
    text-align: center
    +x
        margin-top: 32px
    +m
        margin-top: 24px


.requestCard
    background-color: #EFEFEF
    +dark
        background-color: rgb(39,43,48)