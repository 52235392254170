@import ../../../../../styles/helpers
.video
    width: 100%
.video_subscription_modal
    position: relative
    padding: 50px 35px 35px 35px
    max-width: 93%
    background: $n7
    margin: auto
    margin-top: -330px
    margin-bottom: 70px
    border-radius: 8px
    
    +d
        max-width: calc(100% - 60px)
        padding: 50px 35px 40px 35px
        margin-bottom: 90px
          
    +m
        max-width: 90%
        margin-top: -230px
        padding: 35px 35px 20px 35px
        margin-bottom: 60px
    +s 
        margin-top: -200px
        padding: 30px 25px 15px 25px
        margin-bottom: 60px

    +dark
        background: $n7
    .h2
        font-weight:200
        margin-bottom:20px
        +m
          font-size: 14px   
    .p
        font-weight:200
        margin-bottom:20px
        color:#6F767E
        font-size:15px
        +m
          font-size: 13px  
        +s
          font-size: 12px  
    .line 
        border: 1px solid #272B30

    .subscription_box
        display: flex
        justify-content: space-between

        .price
        display: flex
        align-items: center
        font-weight:200
        margin-bottom:20px
        font-size:24px 

        button
            width: 60%
            +m
              font-size: 13px   
            height: 40px
            +s 
             font-size: 11px
             height: 30px
             border-radius: 8px
        +d 
            font-size:2vw 
        
    &.active
        display: flex
        max-width: 1440px
        +d
            max-width: 100%
            margin-right: 0
           
        .details
            flex: 0 0 calc(100% - 472px)
            width: calc(100% - 472px)
            +d
                display: none
        .comments
            display: block
        .panel,
        .next
            display: none

.title
    margin-top: 18px
    margin-bottom: 20px
    +m
        font-size: 24px

.info
    margin-bottom: 12px
    +title1-m
    color: $n4
    +m
        font-size: 14px

.line
    display: flex
    align-items: center

.author
    display: flex
    align-items: center
    +base2
    color: $n4
    span
        margin-left: 4px
        color: $n7
        +dark
            color: $n1

.avatar
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 12px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.rating
    display: flex
    align-items: center
    margin-left: 12px
    +base2
    svg
        margin-right: 8px
        fill: #FFC554

.counter
    margin-left: 4px
    color: $n4

.gallery
    position: relative
    margin-top: 40px
    +m
        margin-top: 32px
    .button
        position: absolute
        left: 12px
        bottom: 12px
        +m
            bottom: 5px
        +dark
            box-shadow: inset 0 0 0 2px $n6
            background: $n7
            color: $n1
            &:hover
                background: $n6

.preview
    img
        width: 100%
        border-radius: 12px

.row
    display: flex
    margin-top: 40px
    +t
        display: block
    +m
        margin-top: 32px

.col
    &:first-child
        flex-grow: 1
        padding-right: 64px
        +d
            padding-right: 48px
        +t
            padding-right: 0
    &:nth-child(2)
        flex-shrink: 0
        width: 308px
        +t
            width: 100%
            margin-top: 32px

.subtitle
    margin-bottom: 32px
    +t
        margin-bottom: 24px
    +m
        margin-bottom: 12px
.edit_title
    margin: 20px 0px 40px 0px
    .content_edit_buttons
        display: flex
        margin: 20px 0px 20px 0px
        gap: 10px
    .content_edit
        background-color:#2A85FF
        padding: 10px 15px 10px 15px
        font-weight: 700
        font-size: 15px
        max-width: 200px
        border-radius: 12px
    .content_cancel
        background-color:#e74c3c
        padding: 10px
        font-weight: 700
        font-size: 15px
        border-radius: 12px
    input
        border-color: #272B30
        background: #272B30
        color: #FCFCFC
        border-radius: 12px
        width: 100%
        overflow: scroll
        padding: 10px
        font-size: 15px

.content
    font-weight: 500
    display: flex
    flex-direction: column
    gap: 30px
    justify-content: space-between
    color: $n5
    +dark
        color: $n3
    p
        &:not(:last-child)
            margin-bottom: 16px
    .content_edit_buttons
        display: flex
        gap: 10px
    .content_edit
        background-color:#2A85FF
        padding: 10px
        font-weight: 700
        font-size: 15px
        max-width: 200px
        border-radius: 12px
    .content_cancel
        background-color:#e74c3c
        padding: 10px
        font-weight: 700
        font-size: 15px
        border-radius: 12px
    textarea
        border-color: #272B30
        background: #272B30
        color: #FCFCFC
        border-radius: 12px
        width: 100%
        height:150px
        overflow: scroll
        padding: 10px
        font-size: 15px

.features
    +m
        margin-top: 12px
    li
        position: relative
        padding-left: 40px
        color: $n5
        +dark
            color: $n3
        &:before
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 24px
            height: 24px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%23b5e4ca'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        &:not(:last-child)
            margin-bottom: 20px
            padding-bottom: 20px
            border-bottom: 1px solid $n3
            +m
                margin-bottom: 16px
                padding-bottom: 16px
            +dark
                border-color: $n6