.error
    color: firebrick
    width: 100%
.btns
    margin-top : 20px

.title
    margin-bottom : 25px
    
.field
    width: 100%  
    input
        background: #b1b1b1
        &:focus
            border: none
      
.inputBox
    margin-bottom: 10px
    display: flex
    justify-content: space-between
    input
        width: 100%
        background: black
