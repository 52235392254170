@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.card-outer {
    transition: all .3s !important;
}

.stripe-button {
    transition: all.3s !important;
}

.stripe-button.active {
    width: 200px;
}

.stripe-button>* {
    transition: all .3s !important;
    position: relative;
    bottom: 0px;
}

.stripe-button>span:nth-child(2) {
    right: -24px;
}

.stripe-button.active>span:nth-child(1) {
    bottom: -35px;
}

.stripe-button.active>span:nth-child(2) {
    right: 80px;
}

.pagination-button {
    transition: all .3s !important;
}

.pagination-button.pagination-active {
    width: 40px;
    background-color: #2A85FF;
}

.sliders {
    transition: all.3s !important;
}

.deleteModal-overlay {
    transition: all .3s !important;
}


.text-2{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}



.creator-icon::after{
    content: 'This is a creator';
    position: absolute;
    background: rgba(28, 28, 28, 0.41);
    display: block;
    font-size:13px;
    width:130px;
    padding:5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    text-align:center;
    border-radius:5px;
    top:0px;
    left:-43px;
    transition: all .7s; 
    opacity:0;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);     
}

.creator-icon:hover::after{
    opacity: 1;
    top: -33px;
    rotate: 360deg;
}

.creator-icon:active::after{
    opacity: 1;
    top: -33px;
    rotate: 360deg;
}


.banner-change{
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    opacity:0;
    pointer-events: none;
}

.profile-bg:hover .banner-change{
    opacity: 1;
}


#signinBtn{
    background-color:#2A85FF !important;
}

#signinBtn:hover{
    background-color: #115ec2!important
}


/* .price_button{
    box-shadow: inset 0 0 0 2px #272B30;
} */

.price_button:hover{
    box-shadow: inset 0 0 0 2px #33383F;
}

.add-socials{
    box-shadow: inset 0 0 0 2px #272B30;
}

.add-socials:hover{
    box-shadow: inset 0 0 0 2px #33383F;
}
