.error
    color: firebrick
    width: 100%
.btns
    margin-top : 20px
    text-align: end

.title
    margin-bottom : 25px
    
.field
    input   
        border: 1px solid #6F767E
.inputBox
    margin-bottom: 10px
    display: flex
    gap:10px
    input
        width: 100%