@import ../../styles/helpers

.themeSwitchContainer
    display: flex
    align-items: center
    position: relative
    background-color: $n2
    border-radius: 20px
    padding: 2px 2px
    &:after
        content: ""
        position: absolute
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06)
        top: 50%
        transform: translateY(-50%)
        left: 2%
        width: 48%
        height: 75%
        background-color: $n1
        border-radius: 20px
        transition: 0.3s
    @include dark
        background-color: $n8
        &:after
            left: 50%
            background-color: $n6

.themeSwitchContainerSmall
    width: 50px
    height: 50px
    display: flex
    align-items: center
    position: relative
    background-color: $n2
    border-radius: 50%
    padding: 2px 2px
    &:after
        content: ""
        position: absolute
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06)
        top: 50%
        transform: translate(-50%,-50%)
        left: 50%
        width: 35px
        height: 35px
        background-color: $n1
        border-radius: 20px
        transition: 0.3s
    @include dark
        background-color: $n8
        &:after
            background-color: $n6

.themeButtonDark
    display: flex
    position: relative
    z-index: 1
    font-size: 1rem
    gap: 8px
    color: $n4
    align-items: center
    flex-grow: 1
    justify-content: center
    font-weight: 600
    padding: 8px 0px
    & > svg
        fill: $n4
    &:hover
        color: $n6
        & > svg
            fill: $n6
    @include dark
        color: $n1
        & > svg
            fill: $n1

.themeButtonLight
    display: flex
    position: relative
    z-index: 1
    font-size: 1rem
    gap: 8px
    color: $n7
    align-items: center
    flex-grow: 1
    justify-content: center
    font-weight: 600
    padding: 8px 0px
    & > svg
        fill: $n7
    &:hover
        color: $n6
        & > svg
            fill: $n6
    @include dark
        color: $n4
        & > svg
            fill: $n4
        &:hover
            color: $n1
            & > svg
                fill: $n1
