@import ../../../../styles/helpers

.loading
    position: relative
    overflow: hidden

    &::before
        content: ''
        position: absolute
        top: 0
        left: -100%
        width: 200%
        height: 100%
        background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 100%)
        animation: shiftBackground 2s linear infinite

    & > *
        visibility: hidden

    &.loaded > *
        visibility: visible

    @keyframes shiftBackground
        0%
            transform: translateX(-100%)
        100%
            transform: translateX(100%)

.item
    display: flex
    align-items: center
    padding: 12px
    cursor: pointer

.link
    display: flex
    align-items: center
    flex-grow: 1
    &:hover
        .title
            color: $p1

.link a
    color: #676E75

.preview
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 16px
    border-radius: 8px
    overflow: hidden
    img
        width: 100%
        height: 100%
        object-fit: cover

.content
    margin-bottom: 4px
    +caption1
    color: $n4

.title
    color: #676E75
    transition: color .2s
    +dark
        color: $n1

.close
    flex-shrink: 0
    margin-left: 24px
    svg
        fill: $shades1
        transition: fill .2s
    &:hover
        svg
            fill: $p3