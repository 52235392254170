@import ../../../styles/helpers
.error
    color: firebrick
    width: 100%
    
.field
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px
            
.imagePreview
    img 
        width: 100%
        max-height: 500px
    max-height: 500px
    +m 
        height: 300px