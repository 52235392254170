@import ../../styles/helpers
.p
    font-size: 12px
.username
    font-size: 20px
.outer
 max-width: 450px !important
 width: 100% !important

.avatar
    width: 50px
    height: 50px
    border-radius: 50%
    margin-right: 20px

.header
    display: flex
    width: 50%
    justify-content: start
    align-items: center


.container
    border-bottom-width: 0.05em
    border-bottom-color: #6F767E
    border-bottom-style: solid
    margin-bottom: 50px

.Request_btn
 color : #6F767E
 height: 40px
 padding: 0 20px 0 20px
 margin-right: 16px
 box-shadow: inset 0 0 0 2px #272B30
 border-radius: 12px 
 font-weight: 600
 font-size: 14px
 &:hover
        box-shadow: inset 0 0 0 2px $n5

.buttons
    display: flex

    width: 100%
    max-width: fit-content

    button:last-child
        margin-left: 8px
        //background-color: $p1
        //color: #FFFFFF
        //+m
        //    margin-right: 0

.background
    height: 400px
    margin: -40px -40px 0
    +x
        margin: -32px -24px 0
    +d
        height: 320px
    +m
        height: 252px
        margin: -24px -16px 0
    img
        width: 100%
        height: 100%
        object-fit: cover

.card
    max-width: 1200px
    margin: -64px auto 0
    +m
        margin-top: -188px

.control
    display: flex
    align-items: center
    margin-bottom: 32px
    +m
        flex-wrap: wrap

.nav
    display: flex
    margin-right: auto
    //width: fit-content
    +m
        width: 100%
        margin: 0 0 16px
    img
        //width: 100%
        //height: 100%
        object-fit: cover
        border-radius: 50%

.link
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex-grow: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.dropdownBox
    +m
        flex-grow: 1

.filters
    margin-left: 16px
    +m
        margin-left: 12px

.products
    display: flex
    flex-wrap: wrap
    margin: -32px -12px
    &:last-child
        margin-bottom: 20px
    +a
        display: block
        margin: 0

.product
    flex: 0 0 calc(33.333% - 24px)
    width: calc(33.333% - 24px)
    margin: 32px 12px 0
    +t
        flex: 0 0 calc(50% - 24px)
        width: calc(50% - 24px)
    +a
        width: 100%
        margin: 24px 0 0

.foot
    margin-top: 40px
    text-align: center
    +x
        margin-top: 32px
    +m
        margin-top: 24px



.loading
    position: relative
    overflow: hidden

    &::before
        content: ''
        position: absolute
        top: 0
        left: -100%
        width: 200%
        height: 100%
        background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 100%)
        animation: shiftBackground 2s linear infinite

    & > *
        visibility: hidden

    &.loaded > *
        visibility: visible

    @keyframes shiftBackground
        0%
            transform: translateX(-100%)
        100%
            transform: translateX(100%)
