@import ../../../styles/helpers
.error
    color: firebrick
    width: 100%
.video
    width: 100%
    margin-top: 10px
.field
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

