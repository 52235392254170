@import ../../../styles/helpers

.title
    margin-bottom: 20px !important



.row
    display: flex
    +t
        display: block

.col
    flex: 0 0 100%
    
        
    &:nth-child(2)
        padding: 30px 0 0 24px
        +t
            padding: 0

.btns
    display: flex
    margin: 24px -4px 0
    .button
        flex: 0 0 calc(100% - 8px)
        width: calc(100% - 8px)
        margin: 0 4px
        +m
            padding: 0 8px
        
        &:hover
            opacity: 80% 
        

.box
    &:not(:last-child)
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid rgba($shades4, .1)

.info
    margin-bottom: 20px
    +title1-s


.price
    display: flex
    justify-content: center
    padding: 5px
    width: 100%
    position: relative

    .span
        font-size: 8px
        position: absolute
        top: 13%
        right: 3%
        opacity: 40%
        font-color: #7A7F85

.price input
    background: #1A1D1F

.terms
    margin-top: 15px
    opacity: 50%
    font-size: 12px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.terms a
    color: #fff
    text-decoration: underline
    opacity: 100% !important

.error
    color:  #C01D1F

.input 
    width: 100%
    background: #111315    
