/* Skeleton.module.sass */
@import ../../../styles/helpers
.skeletonContainer  // for the whole skeleton
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 32px
  padding-bottom:10px

.skeletonProfileDetailsContainer // for the profile details skeleton
  display: flex
  justify-content: space-between
  gap: 10px

  +a
    flex-direction: column
    gap: 16px


.skeletonProfileContainer // for the profile skeleton
  display: flex
  flex-direction: column
  gap: 10px


.skeletonProductsContainer // for the product skeleton
  display: flex
  gap: 24px
  flex-direction: column

.skeletonLeftContainer // for the left side of the skeleton
  display: flex
  gap: 10px

.skeletonButtonContainer // for the button skeleton
  display: flex
  gap: 10px

.skeletonProductItem // for each product skeleton
  display: flex
  flex-direction: column
  gap: 10px

.skeletonProductDetails // for each product details skeleton
  display: flex
  flex-direction: column
  gap: 10px

.skeletonBannerSection
  display: flex
  gap: 24px 


.skeletonBanner
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 10px

.skeletonProductStats
  display: flex
  align-items: center
  gap: 8px


// -----------------------------------------------------------------------------------------------------------------

.skeletonAvatar // for the avatar skeleton

  min-width: 80px

  animation: glow 1.5s infinite
  height: 80px
  border-radius: 50%
  background-color: $n5

.skeletonUserName // for the username skeleton

  min-width: 100px

  animation: glow 1.5s infinite
  height: 20px
  background-color: $n5
  border-radius: 5px

.skeletonPostCount // for the post count skeleton

  min-width: 50px

  animation: glow 1.5s infinite
  height: 20px
  background-color: $n5
  border-radius: 5px

.skeletonButton // the skeleton of the button

  min-width: 130px

  animation: glow 1.5s infinite
  height: 48px
  background-color: $n5
  border-radius: 12px

.skeletonButton2 // the skeleton of the second button

  min-width: 160px

  animation: glow 1.5s infinite
  height: 48px
  background-color: $n5
  border-radius: 12px


.skeletonProductImage // for each product image skeleton
  width: 100%
  animation: glow 1.5s infinite
  height: 200px
  background-color: $n5
  border-radius: 8px  

.skeletonBannerImage
  width: 100%
  animation: glow 1.5s infinite
  height: 350px
  background-color: $n5
  border-radius: 8px  

.skeletonProductTitle // for each product title skeleton
  width: 155px
  animation: glow 1.5s infinite
  height: 20px
  background-color: $n5
  border-radius: 5px

.skeletonSpan   // for the span skeleton
  display: inline-block
  width:57px
  height: 18px
  background-color: $n5
  animation: glow 1.5s infinite
  border-radius:5px 

.skeletonVideos 
  display: flex
  gap: 24px 

  +t
    display: grid
    grid-template-columns: repeat(2, 1fr)
  +a
    display: grid
    grid-template-columns: repeat(1, 1fr)

.skeletonVideo
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 10px


@keyframes glow 
    0%  
      opacity: 0.6 
    50%  
      opacity: 0.3 
    100% 
      opacity: 0.6 
