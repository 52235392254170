@import ../../styles/helpers

.lockImg
    margin-auto
    
.loading
    position: relative
    overflow: hidden

    &::before
        content: ''
        position: absolute
        top: 0
        left: -100%
        width: 200%
        height: 100%
        background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 100%)
        animation: shiftBackground 2s linear infinite

    & > *
        visibility: hidden

    &.loaded > *
        visibility: visible

    @keyframes shiftBackground
        0%
            transform: translateX(-100%)
        100%
            transform: translateX(100%)

.product
    &:hover
        .preview:after,
        .control,
        .checkbox
            visibility: visible
            opacity: 1  
    &.active
        .checkbox
            visibility: visible
            opacity: 1
        .preview
            &:before
                border-color: $p1

.preview:after,
.control,
.checkbox
    opacity: 0
    visibility: hidden
    transition: all .2s

.preview
    position: relative
    height: 200px
    margin-bottom: 16px
    border-radius: 12px
    overflow: hidden
    &:after,
    &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        transition: all .2s
    &:after
        background: rgba($n8, .8)
    &:before
        z-index: 2
        border: 2px solid transparent
        border-radius: 12px
        pointer-events: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
    .checkbox
        position: absolute
        top: 12px
        left: 12px
        z-index: 3
        +t
            display: none 
        .checkboxTick
            border-color: $p1

.control
    position: absolute
    top: 50%
    left: 50%
    z-index: 10
    transform: translate(-50%,-50%)

.line
    display: flex
    align-items: flex-start

.title
    margin-right: auto
    cursor: pointer

.price,
.empty
    flex-shrink: 0
    display: inline-block
    margin-left: 32px
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    background: $s4
    +dark
        color: $n7

.empty
    background: $n3
    +dark
        background: $n6
        color: $n1

.date
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    color: $n4
    +m
        margin-top: 2px
    svg
        margin-right: 8px
        fill: $n4

.rating
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    +m
        margin-top: 4px
    svg
        margin-right: 8px
        fill: #FFC554
    span
        margin-left: 4px
        color: $n4

.ratingEmpty
    color: $n4
    svg
        fill: $n4