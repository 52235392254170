@import ../../../styles/helpers
.username
    color: black
    font-size: 21px
    +dark
        color: #6F767E
        
.Tagline
    font-size: 15px
    box-shadow: 0 0 0 2px #EFEFEF inset
    +dark
        box-shadow: inset 0 0 0 2px #272B30
.Request_btn
    box-shadow: 0 0 0 2px #EFEFEF inset
    +dark
        box-shadow: inset 0 0 0 2px #272B30

.info   
    span
        width: 80%
        display: block

.addsocials
    margin-right: 15px
.tagline
    font-size: 13px
    margin-top: 8px
    color: $n4
    +m
        margin-top: 4px
        font-size: 13px
.editTagline
    position: absolute
    background: white
    border-radius: 50%
    top: 65px
    left: 105px
    visibility: hidden
    +m
        top: 50px
        left: 100px


.edit
    top: -10px
    position: absolute
    background: white
    border-radius: 50%
  
    
.backgroundImage 
    transition: filter 0.3s ease
        
.backgroundImage:hover
    filter: brightness(50%)

.outer
 max-width: 450px !important
 width: 100% !important

.profile
    display: flex
    align-items: center
    margin-bottom: 40px
    padding-bottom: 40px
    border-bottom: 1px solid $n3
    +d
        margin-bottom: 32px
        padding-bottom: 32px
    +t
        display: block
    +m
        margin-bottom: 32px
        padding-bottom: 32px
    +dark
        border-color: $n6

.details
    display: flex
    align-items: center
    flex-grow: 1
    +t
        margin-bottom: 16px
    +m
        margin-bottom: 12px

.avatar
    position: relative
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.add
    position: absolute
    right: 0
    bottom: 0
    width: 24px
    height: 24px
    border: 2px solid $n
    border-radius: 50%
    background: $p1
    font-size: 0
    transition: background .2s
    svg
        fill: $n
    &:hover
        background: darken($p1, 5)

.man
    font-size: 21px
    +m
        font-size: 20px

.info
    margin-top: 8px
    min-width: 300px
    word-break: break-word
    font-size: 13px
    color: $n4
    +m
        margin-top: 4px
        min-width: auto
        font-size: 13px
    &:hover
         cursor: pointer
         .editTagline
            visibility: visible

.contacts
    display: flex
    justify-content: space-between
    align-items: center
    flex-shrink: 0
    +t
        margin-left: 0
    +s
        flex-direction: column
        align-items: flex-start
        gap: 10px
        div
            width: 100%
        div:nth-child(1)
            display: block
            button
                width: 100%
                justify-content: center
        div:nth-child(2)
            button
                width: 100%
    .socials
        display: flex
        align-items: center
        margin-right: 12px

.social
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
    &:not(:last-child)
        margin-right: 32px

.button
    margin-left: 32px
    +t
        margin-left: auto
    +m
        min-width: 132px