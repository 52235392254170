@import ../../../styles/helpers

.fieldset
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +t
                margin-bottom: 24px
.errorMessage 
  color: red


.successMessage 
  color: green

.row
    display: flex
    margin: 0 -8px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)
        margin: 0 8px
        +m
            width: 100%
            margin: 0
            &:not(:last-child)
                margin-bottom: 24px
    &:not(:last-child)
        margin-bottom: 32px
        +t
            margin-bottom: 24px
.showPassword
    background-color: $n3
    border: 1px solid $n4 
    +dark
        background-color: $n6
        border: 1px solid $n4
        outline: none
        --tw-ring-color: transparent
        --tw-ring-offset-color: transparent