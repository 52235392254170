@import ../../../styles/helpers

.outer
    max-width: 500px
    width: 500px

.fan
    display: flex
    align-items: center
.follower
    display: flex
    align-items: flex-start
    padding-bottom: 24px
    padding-left: 24px
    padding-top: 24px
    transition: 0.4s
    &:hover
        background: #272B30
        transition: 0.4s
    &:not(:last-child)
        margin-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
            
.title
     h2 
        margin-top: 20px
        margin-bottom: 10px
        +title1-m
        font-weight: 400
        color: white

.description
    p
        +title2 
        color: $n4
        font-weight: 400  
        line-height: 30px  
.details
    width:100%
    
    padding-right: 24px
  
    +t
        padding-right: 0
   
.avatar
    flex-shrink: 0
    width: 50px
    height: 50px
    margin-right: 16px
    +m
        width: 64px
        height: 64px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.man
    margin-bottom: 4px
    +title2
    color: white

.list
    display: flex
    align-items: center

.counter
    +caption1
    color: $shades1
    span
        color: $n4
    &:not(:first-child)
        position: relative
        margin-left: 12px
        padding-left: 14px
        &:before
            content: ""
            position: absolute
            top: 5px
            left: 0
            width: 2px
            height: 8px
            border-radius: 2px
            background: $n3
            +dark
                background: $n6

.gallery
    display: flex
    flex-wrap: wrap
    flex-shrink: 0
    width: 480px
    margin: -12px -6px 0 0
    +t
        display: none

.preview
    flex: 0 0 calc(33.333% - 12px)
    width: calc(33.333% - 12px)
    height: 116px
    margin: 12px 6px 0
    img
        width: 100%
        height: 100%
        border-radius: 12px
        object-fit: cover
    &:nth-child(n+4)
        display: none

.btns
    display: flex
    justify-content: space-between
    margin-top: 16px
    .tip
        display: flex
        align-items: center
        gap: 5px
        color : #6F767E
        height: 40px
        padding: 0 20px 0 20px
        margin-right: 16px
        box-shadow: inset 0 0 0 2px #272B30
        border-radius: 12px 
        font-weight: 600
        font-size: 14px
        &:hover
            box-shadow: inset 0 0 0 2px $n5         
    .like
        display: flex
        align-items: center
        gap: 5px
        color : black
        background: rgba(202, 255, 189, 1)
        height: 40px
        padding: 0 20px 0 20px
        margin-right: 16px
        border-radius: 12px 
        font-weight: 600
        font-size: 14px
        &:hover
            transition: 0.2s ease-out
            background: rgb(146 210 131)
    .button
        &.follow
            span
                display: none
            &.active
                box-shadow: inset 0 0 0 2px $n3
                background: $n3
                +dark
                    box-shadow: inset 0 0 0 2px $n5
                    background: $n6
                span
                    display: inline
        &:not(:last-child)
            margin-right: 8px
